<script setup>
	import { ref, watch } from "vue";

	const fullName = ref("");
	const firstName = ref("");
	const lastName = ref("");
	const status = ref(false);
	const bericht = ref("");
	const color = ref("");

	const msg = (statusSend, berichtSend, textcol) => {
		status.value = statusSend;
		bericht.value = berichtSend;
		color.value = textcol;
	};

	watch(fullName, (newValue) => {
		const nameArray = newValue.split(" ");
		firstName.value = nameArray[0];
		lastName.value = nameArray.slice(1).join(" ");
	});

	const email = ref("");

	async function submitForm() {
		const response = await fetch(
			"https://api.sendinblue.com/v3/contacts/doubleOptinConfirmation",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"api-key":
						"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",
				},
				body: JSON.stringify({
					email: email.value,
					attributes: {
						FIRSTNAME: firstName.value,
						LASTNAME: lastName.value,
					},
					includeListIds: [4], // replace with the ID(s) of your contact list(s)
					templateId: 3, // replace with your own template id
					redirectionUrl: "https://damwaldrin.frl", // website after confirm mail
				}),
			}
		);

		if (response.ok) {
			msg(
				true,
				"Er is een verificatie e-mail gestuurd. Controleer je inbox en bevestig je aanmelding alsjeblieft",
				"green"
			);
		} else {
			msg(
				true,
				"Het is niet gelukt om je aan te melden. Probeer het later nog eens.",
				"red"
			);
		}
	}
</script>

<template>
	<section class="bg-white">
		<div class="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
			<div class="mx-auto max-w-[350px] sm:text-center">
				<p class="mb-4 font-extrabold text-gray-900">
					Meld je aan voor de nieuwsbrief
				</p>
				<div v-if="status">
					<p
						:class="{
							'text-red-600': color === 'red',
							'text-green-500': color === 'green',
						}">
						{{ bericht }}
					</p>
				</div>
				<form
					@submit.prevent="submitForm"
					v-else>
					<div class="mx-auto mb-3 max-w-screen-sm items-center space-y-4">
						<div class="relative w-full">
							<div
								class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									class="h-5 w-5 text-gray-500">
									<path
										d="M4 22C4 17.5817 7.58172 14 12 14C16.4183 14 20 17.5817 20 22H18C18 18.6863 15.3137 16 12 16C8.68629 16 6 18.6863 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"></path>
								</svg>
							</div>
							<input
								class="focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-3 pl-10 text-sm text-gray-900"
								placeholder="Naam"
								type="text"
								id="name"
								v-model="fullName" />
						</div>
						<div class="relative flex w-full">
							<label
								for="email"
								class="mb-2 hidden text-sm font-medium text-gray-900 dark:text-gray-300"
								>Email address</label
							>

							<div
								class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
								<svg
									class="h-5 w-5 text-gray-500"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg">
									<path
										d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
									<path
										d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
								</svg>
							</div>
							<input
								class="focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500 block w-full rounded-lg border border-gray-300 bg-gray-50 p-3 pl-10 text-sm text-gray-900"
								placeholder="E-mail"
								type="email"
								id="email"
								required=""
								v-model="email" />
							<div class="w-1/5">
								<button
									@click="submitForm()"
									type="submit"
									class="border-primary-600 hover:bg-primary-800 focus:ring-primary-300 dark:hover:bg-primary-700 dark:focus:ring-primary-800 flex h-full w-[50px] cursor-pointer items-center justify-center rounded-lg border bg-orangeTheme text-center text-sm font-medium text-blueTheme focus:bg-hoverOrange">
									<svg
										class="fill-blueTheme text-blueTheme"
										width="20"
										height="20"
										viewBox="0 0 32 32"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M19.4697 31.0001C19.098 31.0036 18.7327 30.9035 18.4148 30.7109C18.0968 30.5184 17.8388 30.2411 17.6697 29.9101L13.6697 22.3401C13.5537 22.1061 13.5338 21.836 13.6143 21.5876C13.6948 21.3392 13.8693 21.1321 14.1005 21.0106C14.3317 20.8891 14.6012 20.8629 14.8515 20.9376C15.1017 21.0122 15.3129 21.1818 15.4397 21.4101L19.4397 28.9801L28.9997 3.06008L2.99972 12.4901L12.7997 17.7501L21.1197 9.43008C21.308 9.24178 21.5634 9.13599 21.8297 9.13599C22.096 9.13599 22.3514 9.24178 22.5397 9.43008C22.728 9.61838 22.8338 9.87378 22.8338 10.1401C22.8338 10.4064 22.728 10.6618 22.5397 10.8501L13.6897 19.6901C13.5393 19.8407 13.3447 19.9394 13.1342 19.9718C12.9238 20.0042 12.7085 19.9685 12.5197 19.8701L2.08972 14.3301C1.72464 14.1592 1.41971 13.882 1.21491 13.5349C1.0101 13.1877 0.914974 12.7867 0.942002 12.3845C0.96903 11.9824 1.11695 11.5977 1.36637 11.2811C1.61579 10.9645 1.95506 10.7306 2.33972 10.6101L28.2497 1.13008C28.6162 0.97552 29.0205 0.933745 29.4108 1.01009C29.8011 1.08644 30.1599 1.27744 30.4411 1.55869C30.7224 1.83993 30.9134 2.19865 30.9897 2.589C31.0661 2.97935 31.0243 3.38359 30.8697 3.75008L21.3897 29.6601C21.2594 30.0348 21.0206 30.3623 20.7036 30.6009C20.3866 30.8395 20.0059 30.9785 19.6097 31.0001H19.4697Z"
											fill="" />
									</svg>
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</section>
</template>
