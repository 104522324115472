<script setup>
import Nieuwsbrief from "../smallComp/Newsletter.vue";
import { renderRichText } from "@storyblok/vue";
import { computed } from "vue";

const { blok } = defineProps({ blok: Object });
console.log(blok.Hoofdtekst);
const articleContent = computed(() => renderRichText(blok.Hoofdtekst));
</script>
<template>
  <section
    class="relative flex w-4/5 flex-col items-center justify-center gap-6 self-center py-5 md:flex-row"
  >
    <div
      class="order-2 flex w-full flex-col gap-6 md:order-1 md:w-8/12"
      :class="{
        'text-center': blok.Center,
      }"
    >
      <div class="flex flex-col gap-4 leading-[150%]">
        <h1 v-if="blok.Tekst === 'h1'">{{ blok.Titel }}</h1>
        <p v-else class="font-bold text-blackTitle">{{ blok.Titel }}</p>
        <div v-editable="blok" v-html="articleContent"></div>
      </div>
      <div
        class="flex flex-col items-center justify-center gap-6 md:flex-row md:gap-4"
        :class="{ 'md:justify-between': !blok.Center }"
      >
        <StoryblokComponent
          v-for="blok in blok.Feitjes"
          v-if="blok.Feitjes"
          :blok="blok"
          :key="blok._uid"
        />

        <div class="flex flex-col gap-5">
          <StoryblokComponent
            v-if="blok.Knop"
            v-for="blok in blok.Knop"
            :blok="blok"
            :key="blok._uid"
          />
        </div>
      </div>
    </div>

    <div
      class="md:w-4/14 order-1 flex w-3/5 justify-center md:order-2"
      v-if="!blok.Center"
    >
      <StoryblokComponent
        v-if="blok.Logo"
        v-for="blok in blok.Logo"
        :blok="blok"
        :key="blok._uid"
        class="w-2/3"
      />
      <Nieuwsbrief v-if="blok.Nieuwsbrief" class="hidden md:block" />
    </div>
  </section>
</template>
