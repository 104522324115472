<script setup>
	import { ref } from "vue";
	import { useStoryblokApi } from "@storyblok/vue";

	const storyblokApi = useStoryblokApi();
	const blok = ref(null);
	const cacheKey = "sponsors-cache";
	const cacheTTL = 3600 * 1000; // 1 hour in milliseconds

	// Check if the data is available in the cache and has not expired
	const cachedData = localStorage.getItem(cacheKey);
	if (cachedData) {
		const { data, timestamp } = JSON.parse(cachedData);
		if (Date.now() - timestamp < cacheTTL) {
			blok.value = data;
		}
	}

	// Fetch data from Storyblok API if not available in cache or has expired
	if (!blok.value) {
		const { data } = await storyblokApi.get("cdn/stories/", {
			version: "draft",
			starts_with: "sponsoren2/",
		});
		blok.value = data.stories;

		// Cache the data
		localStorage.setItem(
			cacheKey,
			JSON.stringify({ data: blok.value, timestamp: Date.now() })
		);
	}

	// Filter the sponsors
	const sponsors = blok.value;
	const sponsorsGoud = sponsors.filter(
		(sponsor) => sponsor.content.Soort_sponsor === "Goud"
	);
	const nonGoldSponsors = sponsors.filter(
		(sponsor) => sponsor.content.Soort_sponsor !== "Goud"
	);
</script>
<template>
	<section
		class="sponblok relative flex w-theme flex-col items-center justify-center gap-6 self-center py-10">
		<img
			src="https://a.storyblok.com/f/225611/127x124/0ade0d21f3/driehoek.svg"
			alt=""
			class="absolute left-[-120px] top-3 w-24 lg:left-[-60px]" />
		<img
			src="https://a.storyblok.com/f/225611/127x124/0ade0d21f3/driehoek.svg"
			alt=""
			class="absolute bottom-3 right-[-130px] w-24" />
		<h1 class="">Mede mogelijk gemaakt door</h1>
		<div
			class="main-spon container flex flex-col items-center justify-center gap-8 border-b-4 py-6">
			<h2
				v-if="sponsorsGoud.length === 1"
				class="font-light">
				Hoofdsponsor:
			</h2>
			<h2
				v-else-if="sponsorsGoud.length > 1"
				class="font-medium">
				Hoofdsponsoren:
			</h2>

			<div class="flex items-center justify-between gap-10">
				<div
					class="img-container"
					v-for="sponsor in sponsorsGoud">
					<a
						:href="sponsor.content.website.url"
						target="_blank">
						<img
							:src="sponsor.content.Logo.filename"
							:alt="sponsor.content.Logo.alt"
							class="w-60" />
					</a>
				</div>
			</div>
		</div>
		<div
			class="sup-spon container flex flex-wrap items-center justify-center gap-8 py-4">
			<div
				class="img-container w-5/12 md:w-1/4"
				v-for="sponsor in nonGoldSponsors">
				<a
					:href="sponsor.content.website.url"
					target="_blank">
					<img
						:src="sponsor.content.Logo.filename"
						:alt="sponsor.content.Logo.alt"
						class="w-full" />
				</a>
			</div>
		</div>
	</section>
</template>
<style></style>
