<script setup>
import Footer from "../components/Footer.vue";
import { useMeta } from "vue-meta";
import Sponsor from "../components/Main/Sponsor.vue";

import { useRoute } from "vue-router";
import { useStoryblok } from "@storyblok/vue";
const route = useRoute();

let story = null;

const searchProject = route.params.slug;

try {
  story = await useStoryblok(`nieuws/${route.params.slug}`, {
    version: "draft",
  });
} catch (error) {
  console.error(`project ${searchProject} not found`);
}

const meta = useMeta({
  title: story.value.content.Meta.title,
  description: [
    {
      name: "description",
      content: story.value.content.Meta.description,
    },
  ],
});
</script>
<template>
  <main class="flex flex-col">
    <StoryblokComponent
      v-if="story !== null"
      :blok="story.content"
      :tijd="story"
    />
    <div
      v-else
      class="flex h-screen w-full flex-col items-center justify-center gap-3 text-center"
    >
      <h1 class="font-black">404</h1>
      <p>Het gezochte project is niet gevonden</p>
      <p class="font-bold">{{ searchProject }}</p>
    </div>
  </main>
  <div class="flex justify-center">
    <Sponsor />
  </div>
  <Footer />
</template>
