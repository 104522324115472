<script setup>
import { ref } from "vue";
const { blok } = defineProps({
  blok: Object,
});
const open = ref(false);

const handleClick = () => {
  open.value = !open.value;
};
</script>
<template>
  <main class="bg-light-blue">
    <div class="flex items-start justify-center">
      <div class="my-1 w-full sm:w-10/12 md:w-2/3">
        <ul class="flex flex-col">
          <li class="bg-Veelgesteldenvragen shadow-sm" x-data="accordion(1)">
            <p
              @click="handleClick()"
              class="flex cursor-pointer flex-row items-center justify-between p-3 font-semibold text-black"
            >
              <span class="text-sm sm:text-base">{{ blok.Titel }}</span>
              <svg
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="transition-transform duration-500 ease-in-out"
                :class="{ 'rotate-180 transform': open }"
              >
                <path
                  d="M16.4998 2.91602C23.8598 2.91602 29.8332 8.88935 29.8332 16.2493C29.8332 23.6093 23.8598 29.5827 16.4998 29.5827C9.13984 29.5827 3.1665 23.6093 3.1665 16.2493C3.1665 8.88935 9.13984 2.91602 16.4998 2.91602ZM17.8332 16.2493V10.916H15.1665V16.2493H11.1665L16.4998 21.5827L21.8332 16.2493H17.8332Z"
                  fill="#0D5AA5"
                />
              </svg>
            </p>
            <div
              x-ref="tab"
              class="duration- transduration-1000 overflow-hidden border-l-2 border-orangeTheme"
              :class="{
                hidden: !open,
                block: open,
              }"
            >
              <StoryblokComponent
                class="p-3 text-xs"
                v-for="blok in blok.Content"
                :blok="blok"
                :key="blok._uid"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>
