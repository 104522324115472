import { createApp } from "vue";
import { createMetaManager } from "vue-meta";
import App from "./App.vue";

import router from "./router";
import "./assets/main.css";
import { StoryblokVue, apiPlugin } from "@storyblok/vue";
import "./assets/main.css";

// small comps import
import Button from "./components/smallComp/Button.vue";
import Logo from "./components/smallComp/Logo.vue";
import HomeData from "./components/smallComp/HomeData.vue";
import Tekst from "./components/smallComp/Tekst.vue";
import Img from "./components/smallComp/Img.vue";
import Veelgesteld from "./components/smallComp/Veelgesteld.vue";
import Uitslagen from "./components/smallComp/Uitslagen.vue";
import Uitslag from "./components/smallComp/Uitslag.vue";
import Fotos from "./components/smallComp/Fotos.vue";
import Video from "./components/smallComp/Video.vue";
import Route from "./components/smallComp/Route.vue";

// main blocks import
import Page from "./components/Main/Page.vue";
import Hero from "./components/Main/Hero.vue";
import Nieuwsbericht from "./components/Main/Nieuwsbericht.vue";

// big comps import
import Intro from "./components/Bigcomp/Intro.vue";
import IntroDis from "./components/Bigcomp/IntroDis.vue";
import AfstandBlok from "./components/Bigcomp/AfstandBlok.vue";
import VeelgesteldeVragen from "./components/Bigcomp/VeelgesteldeVragen.vue";
import ContactForm from "./components/Bigcomp/ContactForm.vue";
import DeelnemerInfo from "./components/Bigcomp/DeelnemerInfo.vue";
import Routes from "./components/Bigcomp/Routes.vue";
import Nieuwsberichten from "./components/Bigcomp/Nieuwsberichten.vue";
import NieuwsVerzameling from "./components/Bigcomp/NieuwsVerzameling.vue";

const app = createApp(App);

// small comps mount
app.component("Button", Button);
app.component("Logo", Logo);
app.component("HomeData", HomeData);
app.component("Tekst", Tekst);
app.component("Img", Img);
app.component("Veelgesteld", Veelgesteld);
app.component("Uitslagen", Uitslagen);
app.component("Uitslag", Uitslag);
app.component("Fotos", Fotos);
app.component("Video", Video);
app.component("Route", Route);

// main blocks mount
app.component("Page", Page);
app.component("Hero", Hero);
app.component("Nieuwsbericht", Nieuwsbericht);

// big comp mount
app.component("Intro", Intro);
app.component("IntroDis", IntroDis);
app.component("AfstandBlok", AfstandBlok);
app.component("VeelgesteldeVragen", VeelgesteldeVragen);
app.component("ContactForm", ContactForm);
app.component("DeelnemerInfo", DeelnemerInfo);
app.component("Routes", Routes);
app.component("Nieuwsberichten", Nieuwsberichten);
app.component("NieuwsVerzameling", NieuwsVerzameling);

app.use(StoryblokVue, {
  accessToken: "Qx4yRylXuKfh1ixLqjGetAtt",
  bridge: true, // enable the bridge
  use: [apiPlugin],
  cache: true, // enable caching
});

app.use(router);
app.use(createMetaManager());

router.isReady();
app.mount("#app");
