<script setup>
import { ref } from "vue";
const blok = defineProps({ blok: Object });

const afstanden = ref(
  blok.blok.AfstandData.content.Afstanden.map((afstand) => {
    const date = new Date(afstand.Starttijd);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const time = date.toLocaleTimeString("nl-NL", {
      hour: "2-digit",
      minute: "2-digit",
    });

    return {
      ...afstand,
      Starttijd: `${day}/${month}/${year}`,
      StarttijdTime: time,
    };
  })
);
</script>

<template>
  <section
    class="relative flex w-full flex-col items-center justify-center gap-14 self-center py-10 md:w-4/5 lg:w-3/5"
    v-editable="blok"
    :id="blok.blok.Id"
  >
    <div
      class="afstand flex flex-col items-center justify-center gap-5 md:flex-row"
      v-for="afstand in afstanden"
    >
      <div
        class="order-2 flex h-full w-4/5 flex-col justify-center gap-6 md:w-4/6"
        :class="{
          'md:order-1': !afstand.OrderSwitch,
          'md:items-end': afstand.OrderSwitch,
        }"
      >
        <div
          class="flex flex-col gap-1"
          :class="{ 'md:items-end': afstand.OrderSwitch }"
        >
          <h1>{{ afstand.Afstand }} km Damwâldrin</h1>
          <p class="font-bold text-blueTheme">{{ afstand.Starttijd }}</p>
          <p class="font-bold text-blueTheme">{{ afstand.StartLocatie }}</p>
        </div>
        <div
          class="flex w-full justify-around gap-10 md:flex-row md:items-center md:justify-start md:gap-5"
          :class="{
            'md:justify-end': afstand.OrderSwitch,
          }"
        >
          <div class="1 text-center text-blueTheme">
            <h1 class="">{{ afstand.Afstand }} km</h1>
            <p class="font-medium text-blackTitle">Afstand</p>
          </div>
          <div class="2 text-center text-blueTheme">
            <h1 class="">{{ afstand.StarttijdTime }}</h1>
            <p class="font-medium text-blackTitle">Start</p>
          </div>
          <div class="3 text-center text-blueTheme">
            <h1 class="">€{{ afstand.Inschrijfkosten }}</h1>
            <p class="font-medium text-blackTitle">Inschrijfkosten</p>
          </div>
        </div>
        <div class="flex justify-center md:justify-start">
          <StoryblokComponent
            v-if="blok.blok.Knop"
            v-for="blok in blok.blok.Knop"
            :blok="blok"
            :key="blok._uid"
          />
        </div>
      </div>
      <div
        class="order-1 w-3/5 md:w-3/6"
        :class="{
          'order-1': afstand.OrderSwitch,
        }"
      >
        <a :href="afstand.RouteLink.url" target="_blank">
          <img
            :src="afstand.routeAfbeelding.filename"
            :alt="afstand.routeAfbeelding.alt"
            class="h-full w-full rounded-md transition-all ease-in hover:scale-105 hover:shadow-md"
          />
        </a>
      </div>
    </div>
  </section>
</template>
