<script setup>
import { ref } from "vue";
const { blok } = defineProps({ blok: Object });

// Set the date we're counting down to
const countDownDate = new Date(blok.EventDate).getTime();

const inschrijving = ref(blok.Inschrijving);
const matchday = ref(false);
const einde = ref(false);

const days = ref(0);
const hours = ref(0);
const minutes = ref(0);
const seconds = ref(0);

// Update the count down every 1 second
const x = setInterval(function () {
  // Get today's date and time
  const now = new Date().getTime();

  // Find the distance between now and the count down date
  const distance = countDownDate - now;

  // Time calculations for days, hours, minutes and seconds
  days.value = Math.floor(distance / (1000 * 60 * 60 * 24));
  hours.value = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  minutes.value = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  seconds.value = Math.floor((distance % (1000 * 60)) / 1000);

  if (distance < 0 && distance > -86400000) {
    matchday.value = true;
    einde.value = false;
    inschrijving.value = false;
    days.value = 0;
    hours.value = 0;
    minutes.value = 0;
    seconds.value = 0;
  }
  // If the count down is finished, write some text
  if (distance < 0 && distance < -86400000) {
    clearInterval(x);
    einde.value = true;
    inschrijving.value = false;
    days.value = 0;
    hours.value = 0;
    minutes.value = 0;
    seconds.value = 0;
  }
}, 1000);
</script>

<template>
  <div class="order-2 flex flex-col md:order-1">
    <div
      class="timer flex h-fit justify-between gap-5 bg-blueTimer px-2 py-3 sm:gap-10"
    >
      <div class="dagen flex flex-col gap-1 text-center">
        <h1 class="text-orangeTheme">{{ days }}</h1>
        <p class="font-medium tracking-wide text-white">Dagen</p>
      </div>
      <div class="dagen flex flex-col gap-1 text-center">
        <h1 class="text-orangeTheme">{{ hours }}</h1>
        <p class="font-medium tracking-wide text-white">Uren</p>
      </div>
      <div class="dagen flex flex-col gap-1 text-center">
        <h1 class="text-orangeTheme">{{ minutes }}</h1>
        <p class="font-medium tracking-wide text-white">Minuten</p>
      </div>
      <div class="dagen flex flex-col gap-1 text-center">
        <h1 class="text-orangeTheme">{{ seconds }}</h1>
        <p class="font-medium tracking-wide text-white">Seconden</p>
      </div>
    </div>
    <div
      class="flex w-4/5 justify-center self-center bg-blueTimer py-2"
      v-if="inschrijving || einde || matchday"
    >
      <p class="text-sm text-white" v-if="inschrijving">
        De inschrijving is geopend!
      </p>
      <p class="text-sm text-white" v-if="einde">Bekijk de uitslagen.</p>
      <p class="text-sm text-white" v-if="matchday">Wedstrijd dag!!</p>
    </div>
  </div>
</template>
<style scoped></style>
