<script setup>
import { computed } from "vue";
import { renderRichText } from "@storyblok/vue";
const { blok } = defineProps({ blok: Object });
const articleContent = computed(() => renderRichText(blok.Tekst));
</script>
<template>
  <div
    v-editable="blok"
    v-html="articleContent"
    class="text flex flex-col gap-5 leading-5"
  ></div>
</template>
<style>
div p a {
  color: #333333 !important;
  text-decoration: underline !important;
}
div p b {
  color: #333333 !important;
}
</style>
