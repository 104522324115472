<script setup>
	import { ref, computed, watch } from "vue";
	import emailjs from "emailjs-com";

	const { blok } = defineProps({
		blok: Object,
	});

	const name = ref("");
	const email = ref("");
	const message = ref("");
	const formstatus = ref(1);
	const telefoon = ref("");

	const btnStyle = ref("Geel-stroke");
	const anchorValue = ref("");
	const onderwerp = ref("Algemeen");

	const onderwerpChooser = (optie) => {
		onderwerp.value = optie;
	};

	// Check if anchor exists in the slug and extract its value
	const hasAnchor = computed(() => {
		return window.location.hash !== "";
	});

	watch(
		() => window.location.hash,
		(newHash) => {
			anchorValue.value = newHash.substring(1);
		},
		{ immediate: true }
	);

	if (hasAnchor.value) {
		onderwerp.value = decodeURIComponent(anchorValue.value);
	}

	function sendEmail(e) {
		try {
			emailjs
				.sendForm(
					"service_m38wg8w",
					"template_cnsgwul",
					e.target,
					"OkzJSpsMnDa9ERiaf",
					{
						name: name,
						email: email,
						message: message,
						subject: onderwerp,
						tel: telefoon,
					}
				)
				.then((result) => {
					formstatus.value = 2;
					console.log(result.text);
					name.value = "";
					email.value = "";
					message.value = "";
				});
		} catch (error) {
			alert(
				"Er is iets misgegaan bij het versturen van je bericht. Probeer het later nog eens."
			);
		}
	}
</script>

<template>
	<!-- HTML -->
	<form
		v-if="formstatus === 1"
		class="relative mx-auto w-3/4 md:w-1/3"
		name="contact"
		@submit.prevent="sendEmail">
		<h1 class="py-5">Contact, {{ onderwerp }}</h1>
		<div class="mb-4">
			<label
				class="mb-2 block font-bold text-blueTheme"
				for="subject">
				Onderwerp
			</label>
			<div class="relative">
				<select
					class="focus:shadow-outline block w-full appearance-none rounded border border-orangeTheme bg-white px-4 py-2 pr-8 leading-tight shadow hover:border-hoverOrange focus:border-hoverOrange focus:outline-none"
					id="subject"
					name="subject">
					<option
						v-for="optie in blok.OnderwerpOpties"
						@click="onderwerpChooser(optie)"
						:selected="optie === onderwerp">
						{{ optie }}
					</option>
				</select>
				<div
					class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-blueTheme">
					<svg
						width="22"
						height="22"
						viewBox="0 0 33 33"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						class="transition-transform duration-500 ease-in-out">
						<path
							d="M16.4998 2.91602C23.8598 2.91602 29.8332 8.88935 29.8332 16.2493C29.8332 23.6093 23.8598 29.5827 16.4998 29.5827C9.13984 29.5827 3.1665 23.6093 3.1665 16.2493C3.1665 8.88935 9.13984 2.91602 16.4998 2.91602ZM17.8332 16.2493V10.916H15.1665V16.2493H11.1665L16.4998 21.5827L21.8332 16.2493H17.8332Z"
							fill="#0D5AA5" />
					</svg>
				</div>
			</div>
		</div>
		<div class="mb-4">
			<label
				class="mb-2 block font-bold text-blueTheme"
				for="name">
				Naam*
			</label>
			<input
				class="w-full appearance-none border-b border-gray-300 px-3 py-2 leading-tight text-gray-700 focus:border-orangeTheme focus:outline-none"
				id="name"
				name="name"
				type="text"
				placeholder="John Doe"
				required
				v-model="name" />
		</div>
		<div class="mb-4">
			<label
				class="mb-2 block font-bold text-blueTheme"
				for="name">
				Telefoon
			</label>
			<input
				class="w-full appearance-none border-b border-gray-300 px-3 py-2 leading-tight text-gray-700 focus:border-orangeTheme focus:outline-none"
				id="tel"
				name="tel"
				type="tel"
				placeholder="0612345678"
				v-model="telefoon" />
		</div>
		<div class="mb-4">
			<label
				class="mb-2 block font-bold text-blueTheme"
				for="email">
				Email*
			</label>
			<input
				class="w-full appearance-none border-b border-gray-300 px-3 py-2 leading-tight text-gray-700 focus:border-orangeTheme focus:outline-none"
				id="email"
				name="email"
				type="email"
				placeholder="johndoe@example.com"
				required
				v-model="email" />
		</div>
		<div class="mb-4">
			<label
				class="mb-2 block font-bold text-blueTheme"
				for="message">
				Bericht*
			</label>
			<textarea
				class="w-full appearance-none border-b border-gray-300 px-3 py-2 leading-tight text-gray-700 focus:border-orangeTheme focus:outline-none"
				id="message"
				name="message"
				rows="6"
				required
				v-model="message"
				placeholder="Schrijf hier je bericht... "></textarea>
		</div>
		<div class="flex items-center justify-between">
			<button
				type="submit"
				class="flex h-10 w-fit items-center justify-center rounded-sm rounded-tr-lg px-9 py-4 drop-shadow-xl transition-all ease-linear"
				:class="{
					'bg-orangeTheme hover:bg-hoverOrange': btnStyle === 'Geel-fill',
					'bg-white hover:bg-hoverWit': btnStyle === 'Wit',

					'border-2 border-orangeTheme  hover:bg-orangeTheme':
						btnStyle === 'Geel-stroke',
				}">
				<p class="text-sm font-medium tracking-wide text-blueTheme">Verstuur</p>
			</button>
		</div>
	</form>
	<div
		v-else-if="formstatus === 2"
		class="flex h-full w-full flex-col items-center justify-center">
		<h1 class="text-3xl font-bold text-blueTheme">Bedankt voor je bericht!</h1>
		<p class="text-lg text-blueTheme">
			We nemen zo snel mogelijk contact met je op.
		</p>
	</div>
</template>
