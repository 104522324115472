<script setup>
	import { ref } from 'vue';
	import Nav from './Nav.vue';
	import Timer from '../smallComp/Timer.vue';
	import { useStoryblok } from '@storyblok/vue';

	const { blok } = defineProps({ blok: Object });

	const Story = await useStoryblok('data/event-data', {
		version: 'draft',
	});
	// yesy
	const eventDate = new Date(Story.value.content.EventDate);

	const day = eventDate.getDate();
	const month = eventDate.getMonth() + 1; // JavaScript maanden zijn 0-based (0 = januari), dus we moeten er 1 bij optellen.
	const year = eventDate.getFullYear();

	const formattedDate = 'zaterdag 5 oktober 2024';
</script>
<template>
	<section
		class="background flex w-full flex-col gap-6 py-10"
		v-editable="blok">
		<Nav />
		<div
			class="hero-sec flex w-full flex-col gap-8 self-center py-6 md:w-4/5 lg:w-theme">
			<div
				class="text-holder flex flex-col items-center gap-4 xl:flex-row"
				:class="{
					'justify-between': blok.HomeHero,
					'justify-center': !blok.HomeHero,
				}">
				<h1
					class="text-stroke text-center text-5xl uppercase sm:text-6xl md:text-8xl">
					{{ blok.Titel }}
				</h1>

				<div
					class="afstanden flex gap-5 text-center uppercase tracking-wide md:flex-col md:gap-0 lg:gap-6"
					v-if="blok.HomeHero">
					<!-- <p class="font-medium text-white">5km, 10km en 16km</p> -->
					<p class="font-medium text-orangeTheme">{{ formattedDate }}</p>
				</div>
			</div>

			<div
				class="logo-timer-holder flex w-4/5 flex-col items-center gap-8 self-center md:flex-row"
				:class="{
					'md:justify-around': blok.HomeHero,
					'md:justify-center': !blok.HomeHero,
				}"
				v-if="blok.HomeHero || blok.InschrijvenHero">
				<Timer :blok="Story.content" />

				<div
					v-if="blok.Sponsor.filename"
					class="main-sponsor order-1 flex aspect-video w-1/4 items-center justify-center rounded-sm p-4 md:order-2"
					:class="{ ' bg-white': blok.WitteAchtergrond }">
					<img
						:src="blok.Sponsor.filename"
						alt=""
						class="w-full" />
				</div>
			</div>
		</div>
	</section>
	<div
		v-if="blok.HomeHero"
		class="w-full overflow-hidden">
		<svg
			class="h-auto w-full"
			viewBox="0 0 1920 136"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_5_509)">
				<path
					d="M0 69.5V28.5L1920 0.5L1845 80L1509.5 97L1201.5 123.5L873.273 60.5L417.5 123.5L0 69.5Z"
					fill="#FFD910" />
				<path
					d="M0 135.5V40.5L1920 0.5L1488 127L874.5 29L430.5 86L0 135.5Z"
					fill="#22BFE1" />
				<path
					d="M0 95V0H1922L1612.58 76L873.273 32L0 95Z"
					fill="#0F34B1" />
			</g>
			<defs>
				<clipPath id="clip0_5_509">
					<rect
						width="1920"
						height="136"
						fill="white" />
				</clipPath>
			</defs>
		</svg>
	</div>
	<svg
		v-else
		class="h-auto w-full"
		viewBox="0 0 1920 136"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_61_116)">
			<path
				d="M0 135.5V40.5L1920 0.5L1488 127L874.5 29L430.5 86L0 135.5Z"
				fill="#22BFE1" />
			<path
				d="M0 95V0H1922L1612.58 76L873.273 32L0 95Z"
				fill="#0F34B1" />
		</g>
		<defs>
			<clipPath id="clip0_61_116">
				<rect
					width="1920"
					height="136"
					fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
<style>
	.background {
		position: relative;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.background::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url(https://a.storyblok.com/f/225611/640x259/b8b8aff246/backgournd-normaal.png);
		opacity: 0.2;
		background-repeat: no-repeat;
		background-size: cover;
		z-index: -1;
	}
	.background::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(0deg, #0f33b2 0%, #0c68a0 100%);
		z-index: -2;
	}
	.text-stroke {
		-webkit-text-stroke: 2px white;
		text-stroke: 2px white;
		color: transparent;
	}
</style>
