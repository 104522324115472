<script setup>
import { RouterView } from "vue-router";
import { useMeta } from "vue-meta";

const meta = useMeta({
  title: "Home",
  description: [
    {
      name: "description",
      content: "Damwâldrin, bekijk de verschillende afstanden!",
    },
  ],
  htmlAttrs: { lang: "en", amp: true },
});
</script>

<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | Damwâldrin` : `Damwâldrin`
    }}</template>
  </metainfo>
  <Suspense>
    <RouterView />
  </Suspense>
</template>
