<script setup>
	import { ref } from "vue";
	import { RouterLink } from "vue-router";
	import { useStoryblokApi } from "@storyblok/vue";

	const storyblokApi = useStoryblokApi();
	const blok = ref(null);
	const cacheKey = "website-nav-data";
	const cacheTTL = 86400000; // 1 day in milliseconds

	// // Check if the data is available in the cache and has not expired
	// const cachedData = localStorage.getItem(cacheKey);
	// if (cachedData) {
	// 	const { data, timestamp } = JSON.parse(cachedData);
	// 	if (Date.now() - timestamp < cacheTTL) {
	// 		blok.value = data.story.content;
	// 	}
	// }

	// Fetch data from Storyblok API if not available in cache or has expired

	const { data } = await storyblokApi.get("cdn/stories/data/website-data", {
		version: "draft",
	});
	blok.value = data.story.content;

	// // Cache the data
	// localStorage.setItem(
	// 	cacheKey,
	// 	JSON.stringify({ data, timestamp: Date.now() })
	// );

	// Set up reactive ref for menu status
	const menuStatus = ref(false);

	// Method to toggle the menu status
	const toggleNav = () => {
		menuStatus.value = !menuStatus.value;
		// Add or remove the overflow-hidden class to the body element
		document.body.classList.toggle("overflow-hidden", menuStatus.value);
	};
</script>

<template>
	<header
		class="flex w-full justify-between md:justify-center"
		v-editable="blok">
		<div
			class="container mr-4 flex h-full items-center justify-between gap-4 border-t-2 border-white border-opacity-20 md:mr-0">
			<RouterLink to="/">
				<img
					class="max-w-[300px]"
					:src="blok.Logo.filename"
					alt="Logo" />
			</RouterLink>
			<div
				@click="toggleNav"
				class="mr-5 flex flex-col justify-end md:hidden">
				<button
					type="button"
					class="text-white hover:text-hoverWit focus:text-hoverWit focus:outline-none"
					aria-label="toggle menu">
					<svg
						viewBox="0 0 24 24"
						class="h-10 w-10 fill-current">
						<path
							fill-rule="evenodd"
							d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"></path>
					</svg>
				</button>
				<nav
					v-if="menuStatus"
					class="absolute left-0 top-0 flex h-full w-full flex-col justify-center bg-blueTheme text-white md:hidden">
					<button
						@click="closeNav"
						class="absolute right-2 top-2 z-20">
						<svg
							viewBox="0 0 20 20"
							fill="currentColor"
							class="x h-6 w-6">
							<path
								fill-rule="evenodd"
								d="M2.93 2.929a1 1 0 1 1 1.414-1.414l14 14a1 1 0 0 1-1.414 1.414l-14-14z"
								clip-rule="evenodd" />
							<path
								fill-rule="evenodd"
								d="M2.929 15.071a1 1 0 0 1 1.414-1.414l14 14a1 1 0 1 1-1.414 1.414l-14-14z"
								clip-rule="evenodd" />
						</svg>
					</button>
					<ul
						class="ml-5 flex flex-col space-x-8 text-3xl font-medium tracking-wide">
						<li
							v-for="blok in blok.Header_Nav"
							class="">
							<router-link
								:to="'/' + blok.link.cached_url"
								class="py-3 text-lg hover:text-blackTheme">
								{{ blok.label }}
							</router-link>
						</li>
						<div
							v-editable="blok"
							class="mt-3">
							<StoryblokComponent
								v-for="blok in blok.HeaderButtons"
								:blok="blok"
								:key="blok._uid" />
						</div>
					</ul>
				</nav>
			</div>

			<nav
				v-if="blok.Header_Nav"
				class="hidden md:block">
				<ul class="flex space-x-5 text-lg font-medium tracking-wide">
					<li v-for="blok in blok.Header_Nav">
						<router-link
							:to="'/' + blok.link.cached_url"
							active-class="active"
							class="py-3 text-sm text-white hover:text-orangeTheme">
							{{ blok.label }}
						</router-link>
					</li>
				</ul>
			</nav>
			<div
				v-editable="blok"
				class="hidden md:block">
				<StoryblokComponent
					v-for="blok in blok.HeaderButtons"
					:blok="blok"
					:key="blok._uid" />
			</div>
		</div>
	</header>
</template>
<style>
	.active {
		border-top: 2px solid #ffd910;
	}
	.overflow-hidden {
		overflow: hidden;
	}
</style>
