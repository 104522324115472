<script setup>
	import { useStoryblokApi } from "@storyblok/vue";
	import { ref } from "vue";
	const storyblokApi = useStoryblokApi();

	const { data } = await storyblokApi.get("cdn/stories/", {
		version: "published",
		starts_with: "nieuws/",
	});

	const { blok } = defineProps({ blok: Object });

	const btnStyle = "Wit";

	const sortedStories = ref(
		data.stories
			.sort((a, b) => {
				return new Date(b.published_at) - new Date(a.published_at);
			})
			.reverse()
	);
</script>
<template>
	<section
		class="relative flex w-4/5 flex-col items-center justify-center gap-8 self-center py-16 sm:gap-20">
		<div class="nieuws-container flex flex-col flex-wrap gap-2 lg:flex-row">
			<div
				class="card flex w-3/4 flex-col justify-around gap-6 rounded-lg p-4 py-10 shadow-lg lg:flex-row"
				v-for="nieuws in sortedStories"
				:key="nieuws.id">
				<div class="flex flex-col gap-2">
					<p class="font-bold text-black">{{ nieuws.name }}</p>
					<img
						:src="
							nieuws.content.PreviewAfbeelding &&
							nieuws.content.PreviewAfbeelding.filename
						"
						:alt="
							nieuws.content.PreviewAfbeelding &&
							nieuws.content.PreviewAfbeelding.alt
						" />
				</div>

				<div class="flex w-full flex-col justify-center gap-2">
					<p class="text-xs leading-6">
						{{ nieuws.content.Meta && nieuws.content.Meta.description }}
					</p>
					<div class="datum flex items-center gap-3 self-start">
						<svg
							width="21"
							height="20"
							viewBox="0 0 21 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M18.0352 2.5H16.75V4.08984C16.75 4.96875 15.8437 5.625 14.9062 5.625C13.9687 5.625 13 4.96875 13 4.08984V2.5H8V4.08984C8 4.96875 7.0625 5.625 6.125 5.625C5.1875 5.625 4.25 4.96875 4.25 4.08984V2.5H2.96484C2.29297 2.5 1.75 3.01953 1.75 3.64844V17.6133C1.75 18.2422 2.29297 18.75 2.96484 18.75H18.0352C18.707 18.75 19.25 18.2422 19.25 17.6094V3.64844C19.25 3.01953 18.707 2.5 18.0352 2.5ZM17.375 16.4023C17.375 16.6602 17.1484 16.8711 16.875 16.8711L4.12109 16.875C3.84766 16.8633 3.625 16.6562 3.625 16.3984V7.35547C3.625 7.08594 3.85547 6.875 4.14453 6.875H16.8594C17.1445 6.875 17.375 7.08203 17.375 7.34766V16.4023Z"
								fill="#0D5AA5" />
							<path
								d="M7.375 3.75C7.375 4.44141 6.81641 5 6.125 5C5.43359 5 4.875 4.44141 4.875 3.75V2.5C4.875 1.80859 5.43359 1.25 6.125 1.25C6.81641 1.25 7.375 1.80859 7.375 2.5V3.75Z"
								fill="#0D5AA5" />
							<path
								d="M16.125 3.75C16.125 4.44141 15.5664 5 14.875 5C14.1836 5 13.625 4.44141 13.625 3.75V2.5C13.625 1.80859 14.1836 1.25 14.875 1.25C15.5664 1.25 16.125 1.80859 16.125 2.5V3.75Z"
								fill="#0D5AA5" />
						</svg>
						<p>{{ new Date(nieuws.published_at).toLocaleDateString() }}</p>
					</div>
					<button
						v-editable="blok"
						class="flex h-10 w-fit items-center justify-center self-start rounded-sm rounded-tr-lg px-9 py-4 drop-shadow-xl transition-all ease-linear"
						:class="{
							'bg-orangeTheme hover:bg-hoverOrange': btnStyle === 'Geel-fill',
							'bg-white hover:bg-hoverWit': btnStyle === 'Wit',
							'border-2 border-orangeTheme  hover:bg-orangeTheme':
								btnStyle === 'Geel-stroke',
						}">
						<router-link
							:to="nieuws.full_slug"
							class="text-sm font-medium tracking-wide text-blueTheme">
							Lees meer
						</router-link>
					</button>
				</div>
			</div>
		</div>
	</section>
</template>
