<script setup>
import { ref } from "vue";
const { blok } = defineProps({ blok: Object });

var date_string = blok.Datum;
var date_object = new Date(date_string);
var day = date_object.getDate();
var month = date_object.toLocaleString("default", { month: "long" });
var year = date_object.getFullYear();

var formatted_date = day + " " + month + " " + year;

const btnStyle = ref("Geel-stroke");
const extraStyle = ref("Wit");
const isVisual = ref(false);

const calander = () => {
  if (btnStyle.value === "Geel-fill") {
    btnStyle.value = "Geel-stroke";
  } else {
    btnStyle.value = "Geel-fill";
  }

  isVisual.value = !isVisual.value;
};

function addToCalendar(distance) {
  let ical;

  // Assign the appropriate iCal file based on the distance
  if (distance === "5km") {
    ical = blok.Agenda5.filename;
  }

  // Create a temporary link element
  const link = document.createElement("a");
  link.href = ical;
  link.download = "event.ics";

  // Trigger the download
  link.click();
}

// Event listener to handle the "add-to-calendar" event
document.addEventListener("add-to-calendar", (event) => {
  const { url } = event.detail;
  window.open(url, "_blank");
});
// Event listener to handle the "add-to-calendar" event
document.addEventListener("add-to-calendar", (event) => {
  const { url } = event.detail;
  window.open(url, "_blank");
});
</script>

<template>
  <section
    class="flex h-full w-full flex-col gap-8 self-center md:w-4/5 md:flex-row md:gap-0"
    v-editable="blok"
  >
    <div class="Links ml-10 flex w-full flex-col gap-8 md:ml-0 md:w-1/2">
      <!-- inschrijfkosten -->
      <div class="inschrijfkosten flex flex-col gap-3">
        <h1>Inschrijfkosten</h1>
        <div>
          <p class="font-bold text-blueTheme">5km: €{{ blok.Afstand5km }}</p>

          <p class="font-bold text-blueTheme">10km: €{{ blok.Afstand10km }}</p>
        </div>
      </div>

      <!-- starttijden -->
      <div class="Starttijden flex flex-col gap-3">
        <div class="flex flex-col gap-2">
          <h1>Starttijden</h1>
          <p>{{ formatted_date }}</p>
        </div>
        <div>
          <p class="font-bold text-blueTheme">5km: {{ blok.Tijd5km }}</p>
          <p class="font-bold text-blueTheme">10km: {{ blok.Tijd10km }}</p>
        </div>
      </div>
      <div class="flex flex-col gap-3">
        <button
          @click="calander"
          class="flex h-10 w-fit items-center justify-center gap-3 rounded-sm rounded-tr-lg px-9 py-6 font-medium text-blueTheme drop-shadow-xl transition-all ease-linear md:py-4"
          :class="{
            'bg-orangeTheme hover:bg-hoverOrange': btnStyle === 'Geel-fill',

            'border-2 border-orangeTheme  hover:bg-orangeTheme':
              btnStyle === 'Geel-stroke',
          }"
        >
          Voeg toe aan agenda
        </button>
        <div class="flex gap-3" v-if="isVisual">
          <button
            @click="addToCalendar('5km')"
            class="flex h-10 w-fit items-center justify-center gap-3 rounded-sm rounded-tr-lg px-7 py-3 font-medium text-blueTheme drop-shadow-xl transition-all ease-linear"
            :class="{
              'bg-orangeTheme hover:bg-hoverOrange': btnStyle === 'Geel-fill',
              'bg-white hover:bg-hoverWit': extraStyle === 'Wit',
            }"
          >
            5km
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 4.89062H19C19.5304 4.89063 20.0391 5.10134 20.4142 5.47641C20.7893 5.85148 21 6.36019 21 6.89062V20.8906C21 21.4211 20.7893 21.9298 20.4142 22.3048C20.0391 22.6799 19.5304 22.8906 19 22.8906H5C4.46957 22.8906 3.96086 22.6799 3.58579 22.3048C3.21071 21.9298 3 21.4211 3 20.8906V6.89062C3 5.79062 3.9 4.89062 5 4.89062H7V3.89062C7 3.62541 7.10536 3.37105 7.29289 3.18352C7.48043 2.99598 7.73478 2.89063 8 2.89062C8.26522 2.89063 8.51957 2.99598 8.70711 3.18352C8.89464 3.37105 9 3.62541 9 3.89062V4.89062H15V3.89062C15 3.62541 15.1054 3.37105 15.2929 3.18352C15.4804 2.99598 15.7348 2.89063 16 2.89062C16.2652 2.89063 16.5196 2.99598 16.7071 3.18352C16.8946 3.37105 17 3.62541 17 3.89062V4.89062ZM15 6.89062H9V7.89062C9 8.15584 8.89464 8.4102 8.70711 8.59773C8.51957 8.78527 8.26522 8.89062 8 8.89062C7.73478 8.89062 7.48043 8.78527 7.29289 8.59773C7.10536 8.4102 7 8.15584 7 7.89062V6.89062H5V10.8906H19V6.89062H17V7.89062C17 8.15584 16.8946 8.4102 16.7071 8.59773C16.5196 8.78527 16.2652 8.89062 16 8.89062C15.7348 8.89062 15.4804 8.78527 15.2929 8.59773C15.1054 8.4102 15 8.15584 15 7.89062V6.89062ZM19 12.8906H5V20.8906H19V12.8906Z"
                fill="#0D5AA5"
              />
            </svg>
          </button>
          <button
            @click="addToCalendar('10km')"
            class="flex h-10 w-fit items-center justify-center gap-3 rounded-sm rounded-tr-lg px-7 py-3 font-medium text-blueTheme drop-shadow-xl transition-all ease-linear"
            :class="{
              'bg-orangeTheme hover:bg-hoverOrange': btnStyle === 'Geel-fill',
              'bg-white hover:bg-hoverWit': extraStyle === 'Wit',
            }"
          >
            10km
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 4.89062H19C19.5304 4.89063 20.0391 5.10134 20.4142 5.47641C20.7893 5.85148 21 6.36019 21 6.89062V20.8906C21 21.4211 20.7893 21.9298 20.4142 22.3048C20.0391 22.6799 19.5304 22.8906 19 22.8906H5C4.46957 22.8906 3.96086 22.6799 3.58579 22.3048C3.21071 21.9298 3 21.4211 3 20.8906V6.89062C3 5.79062 3.9 4.89062 5 4.89062H7V3.89062C7 3.62541 7.10536 3.37105 7.29289 3.18352C7.48043 2.99598 7.73478 2.89063 8 2.89062C8.26522 2.89063 8.51957 2.99598 8.70711 3.18352C8.89464 3.37105 9 3.62541 9 3.89062V4.89062H15V3.89062C15 3.62541 15.1054 3.37105 15.2929 3.18352C15.4804 2.99598 15.7348 2.89063 16 2.89062C16.2652 2.89063 16.5196 2.99598 16.7071 3.18352C16.8946 3.37105 17 3.62541 17 3.89062V4.89062ZM15 6.89062H9V7.89062C9 8.15584 8.89464 8.4102 8.70711 8.59773C8.51957 8.78527 8.26522 8.89062 8 8.89062C7.73478 8.89062 7.48043 8.78527 7.29289 8.59773C7.10536 8.4102 7 8.15584 7 7.89062V6.89062H5V10.8906H19V6.89062H17V7.89062C17 8.15584 16.8946 8.4102 16.7071 8.59773C16.5196 8.78527 16.2652 8.89062 16 8.89062C15.7348 8.89062 15.4804 8.78527 15.2929 8.59773C15.1054 8.4102 15 8.15584 15 7.89062V6.89062ZM19 12.8906H5V20.8906H19V12.8906Z"
                fill="#0D5AA5"
              />
            </svg>
          </button>
        </div>
      </div>

      <!-- start/fin locatie -->
      <div class="Start/finishLocatie flex flex-col gap-3">
        <h1>Start/finish locatie</h1>
        <p class="font-bold text-blueTheme">{{ blok.Locatie }}</p>
      </div>
    </div>
    <div class="rechts flex w-full items-center justify-center md:w-1/2">
      <div class="rounded-xl active:border-none" v-html="blok.GoogleMap"></div>
    </div>
  </section>
</template>
