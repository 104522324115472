<script setup>
	import { useStoryblok } from "@storyblok/vue";
	import { version } from "vue";
	import { useMeta } from "vue-meta";

	const story = await useStoryblok(
		"deelnemers",
		{
			version: "draft",
			resolve_relations: ["AfstandBlok.AfstandData", "IntroDis.Afstanden"],
		}
		// {
		//   resolveRelations: ["AfstandBlok.AfstandData"],
		// }
	);
	const meta = useMeta({
		title: story.value.content.Meta.title,
		description: [
			{
				name: "description",
				content: story.value.content.Meta.description,
			},
		],
	});
</script>

<template>
	<StoryblokComponent
		v-if="story"
		:blok="story.content" />
</template>
