<script setup>
import { useStoryblok } from "@storyblok/vue";
import { version } from "vue";
import { useMeta } from "vue-meta";

const story = await useStoryblok(
  "inschrijven",
  {
    version: "draft",
    resolve_relations: ["AfstandBlok.AfstandData", "IntroDis.Afstanden"],
  }
  // {
  //   resolveRelations: ["AfstandBlok.AfstandData"],
  // }
);
if (
  story.value.content.Meta.title == !undefined &&
  story.value.content.Meta.description == !undefined
) {
  const meta = useMeta({
    title: story.value.content.Meta.title,
    description: [
      {
        name: "description",
        content: story.value.content.Meta.description,
      },
    ],
  });
}
</script>

<template>
  <StoryblokComponent v-if="story" :blok="story.content" />
</template>
