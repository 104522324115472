<script setup>
	import { ref } from "vue";
	const { blok } = defineProps({ blok: Object });
	const btnStyle = ref("");
	btnStyle.value = blok.style;

	const anchor = ref();

	const linkCached = ref();

	if (blok.link.anchor != undefined) {
		anchor.value = blok.link.anchor;
		linkCached.value = blok.link.cached_url + "#" + anchor.value;
	} else {
		linkCached.value = blok.link.cached_url;
	}
</script>
<template>
	<button
		v-editable="blok"
		class="flex h-10 w-fit items-center justify-center rounded-sm rounded-tr-lg px-9 py-4 drop-shadow-xl transition-all ease-linear"
		:class="{
			'bg-orangeTheme hover:bg-hoverOrange': btnStyle === 'Geel-fill',
			'bg-white hover:bg-hoverWit': btnStyle === 'Wit',
			'self-center': blok.Center,
			'border-2 border-orangeTheme  hover:bg-orangeTheme':
				btnStyle === 'Geel-stroke',
		}">
		<a
			v-if="blok.link.linktype === 'url'"
			:href="blok.link.cached_url"
			:target="blok.link.target"
			class="text-sm font-medium tracking-wide text-blueTheme">
			{{ blok.Label }}
		</a>
		<router-link
			:to="'/' + linkCached"
			class="text-sm font-medium tracking-wide text-blueTheme"
			v-else>
			{{ blok.Label }}
		</router-link>
	</button>
</template>
