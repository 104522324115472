import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import DeelnemersInformatie from "../views/DeelnemersView.vue";
import Contact from "../views/Contactview.vue";
import Inschrijven from "../views/Inschrijvenview.vue";
import EerdereEditie from "../views/EerdereEditieview.vue";
import Vrijwilligers from "../views/Vrijwilligersview.vue";
import nieuwsberichten from "../views/Nieuwsberichtenview.vue";

import Newspaper from "../views/Newspaper.vue";

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: "/",
			name: "home",
			component: HomeView,
		},
		{
			path: "/sponsoren",
			name: "sponsoren",
			component: () => import("../views/SponsorenView.vue"),
		},
		{
			path: "/nieuws/:slug",
			name: "nieuws",
			component: Newspaper,
		},
		{
			path: "/deelnemers",
			name: "Deelnemers",
			component: DeelnemersInformatie,
		},
		{
			path: "/contact",
			name: "Contact",
			component: Contact,
		},
		{
			path: "/inschrijven",
			name: "Inschrijven",
			component: Inschrijven,
		},
		{
			path: "/eerdere-edities",
			name: "EerdereEditie",
			component: EerdereEditie,
		},
		{
			path: "/vrijwilligers",
			name: "Vrijwilligers",
			component: Vrijwilligers,
		},
		{
			path: "/nieuwsberichten",
			name: "nieuwsberichten",
			component: nieuwsberichten,
		},
		{
			path: "/:pathMatch(.*)*",
			name: "not-found",
			component: () => import("../views/NotFoundView.vue"),
		},
	],
});

export default router;
